import styled from 'styled-components';

import grid from '../../assets/images/gridBG.svg';

export const GDPRStyles = styled.section`
  position: relative;
  overflow: hidden;

  padding: 32px 0;

  @media (min-width: 768px) {
    padding: 64px 0;
  }

  @media (min-width: 768px) {
    padding: 80px 0;
  }
  
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    height: 700px;
    margin: auto;
    width: 1500px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(${grid});
    background-repeat: repeat;
  }
  
  &.privacyPolicy {
    &::after {
      content: '';
      position: absolute;
      top: -150px;
      left: 50%;
      transform: translateX(-50%);
      height: 600px;
      margin: auto;
      width: 1100px;
      background: rgb(68,0,195);
      background: linear-gradient(0deg, rgba(68,0,195,0.9500175070028011) 15%, rgba(76,37,171,1) 48%);
      filter: blur(200px);
    }
  }  
  
  &.terms {
    &::after {
      content: '';
      position: absolute;
      top: -150px;
      left: 50%;
      transform: translateX(-50%);
      height: 600px;
      margin: auto;
      width: 1100px;
      background: rgb(0,189,143);
      background: linear-gradient(180deg, rgba(0,189,143,1) 47%, rgba(0,228,255,0.7259278711484594) 100%);
      //mask-image: radial-gradient(rgba(0, 0, 0, 1) 0%, transparent 55%)
      filter: blur(200px);
    }
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 12px;

    opacity: 0;
    transition: 1s ease opacity;

    &.active {
      opacity: 1;
    }

    @media (min-width: 768px) {
      gap: 16px;
    }


    h1 {
      text-align: center;
    }
  }
  
  .container {
    position: relative;
    z-index: 1;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
      color: #00D1FF;
      text-decoration: underline;
    }
    
    .block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
`;
