import styled from 'styled-components';

export const MenuStyles = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  position: fixed;
  top: 84px;
  z-index: 3;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
  height: calc(100vh - 84px);
  height: calc(100dvh - 84px);
  width: 100vw;
  box-sizing: border-box;
  
  padding-bottom: 40px;
  
  transform: translateX(-100%);
  transition: 0.3s ease transform;
  
  &.open {
    transform: translateX(0);
  }
`;
