import React from 'react';
import Header from '../../header/Header';
import { Outlet } from 'react-router';
import { MainLayoutStyles } from './MainLayoutStyles';
import Footer from '../../footer/Footer';

function MainLayout() {
  return (
    <MainLayoutStyles>
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />
    </MainLayoutStyles>
  );
}

export default MainLayout;
