import styled from 'styled-components';

export const TextButtonStyles = styled.button`
  padding: 14px 40px;
  
  text-align: center;
  font-family: Ranade Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border-radius: 4px;
  
  transition: 0.3s ease box-shadow, 0.3s ease color, 0.3s ease background, 0.3s ease opacity, 0.3s ease border;

  &.inherit {
    color: #FFF;
    border: 1px solid #FFF;
    box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.30), 0px 0px 12px 1px rgba(255, 255, 255, 0.40);

    &:hover {
      color: #E0E0E0;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.30), 0px 0px 12px 1px rgba(255, 255, 255, 0.40);
    }

    &:disabled {
      background: rgba(255, 255, 255, 0.35);
      opacity: 0.35;
    }
  }
  
  &.white {
    color: #121212;
    background: #FFF;
    box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.30), 0px 0px 12px 1px rgba(255, 255, 255, 0.40);
    
    &:hover {
      background: #E0E0E0;
      box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.30), 0px 0px 12px 1px rgba(255, 255, 255, 0.40);
    }
    
    &:disabled {
      background: rgba(255, 255, 255, 0.35);
    }
  }
`;
