import { addDays, differenceInHours } from 'date-fns';
import React from 'react';

function calculateTimeElapsed() {
  const specifiedDate = new Date('2023-12-05T15:06:43.169Z');

  const currentDate = new Date();

  return Math.floor(differenceInHours(currentDate, specifiedDate) / 3);
}

function Counter() {
  return (
    <span className="counter textBold48" style={{ background: '#00C1A8', height: 'fit-content', padding: '0 16px' }}>
      {92 + calculateTimeElapsed()}
    </span>
  );
}

export default Counter;
