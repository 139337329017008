import styled from 'styled-components';

import grid from '../../../assets/images/gridBG.svg';
import bg from '../../../assets/images/banner/bannerBg.svg';
import bgTablet from '../../../assets/images/banner/bannerBgTablet.svg';
import bgMobile from '../../../assets/images/banner/bannerBgMobile.svg';

export const BannerStyles = styled.section`
  position: relative;

  .bannerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    height: calc(100vh - 84px);
    height: calc(100svh - 84px);

    @media (min-width: 768px) {
      height: calc(100vh - 92px);
      height: calc(100svh - 92px);
    }

    .bg {
      position: absolute;
      will-change: background-position, transform;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${bgMobile});
      background-repeat: no-repeat;
      background-size: cover;
      animation: bg-pan-right 8s both infinite;

      mask-image: linear-gradient(0deg, transparent 5%, rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 1) 0%, transparent 95%);

      //
      //-webkit-animation: kenburns-right 5s ease-out both infinite;
      //animation: kenburns-right 5s ease-out both infinite;

      @media (min-width: 768px) {
        background-image: url(${bgTablet});
        //
        //-webkit-animation: kenburns-right 7s ease-out both infinite;
        //animation: kenburns-right 7s ease-out both infinite;
      }

      @media (min-width: 1200px) {
        background-image: url(${bg});
        //
        //-webkit-animation: kenburns-right 10s ease-out both infinite;
        //animation: kenburns-right 10s ease-out both infinite;
      }
    }

    .grid {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-image: url(${grid});
      background-repeat: repeat;
    }

    h1 {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      opacity: 0;
      transition: 1s ease opacity;
      //-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      //animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

      &.active {
        opacity: 1;
      }

      .greenBg {
        background: #00C1A8;
        width: fit-content;
        margin: auto;
        padding: 8px 16px;

        min-height: 36px;

        @media (min-width: 768px) {
          min-height: 48px;
        }

        @media (min-width: 1024px) {
          min-height: 90px;
        }
      }
  }
}
  //
  //@-webkit-keyframes tracking-in-expand {
  //  0% {
  //    letter-spacing: -0.5em;
  //    opacity: 0;
  //  }
  //  40% {
  //    opacity: 0.6;
  //  }
  //  100% {
  //    opacity: 1;
  //  }
  //}
  //@keyframes tracking-in-expand {
  //  0% {
  //    letter-spacing: -0.5em;
  //    opacity: 0;
  //  }
  //  40% {
  //    opacity: 0.6;
  //  }
  //  100% {
  //    opacity: 1;
  //  }
  //}

  //@-webkit-keyframes kenburns-right {
  //  0% {
  //    -webkit-transform: scale(1) translate(0, 0);
  //    transform: scale(1) translate(0, 0);
  //    -webkit-transform-origin: 84% 50%;
  //    transform-origin: 84% 50%;
  //  }
  //  50% {
  //    -webkit-transform: scale(1.25) translateX(20px);
  //    transform: scale(1.25) translateX(20px);
  //    -webkit-transform-origin: right;
  //    transform-origin: right;
  //  }
  //  100% {
  //    -webkit-transform: scale(1) translate(0, 0);
  //    transform: scale(1) translate(0, 0);
  //    -webkit-transform-origin: 84% 50%;
  //    transform-origin: 84% 50%;
  //  }
  //}
  //
  //@keyframes kenburns-right {
  //  0% {
  //    -webkit-transform: scale(1) translate(0, 0);
  //    transform: scale(1) translate(0, 0);
  //    -webkit-transform-origin: 84% 50%;
  //    transform-origin: 84% 50%;
  //  }
  //  50% {
  //    -webkit-transform: scale(1.25) translateX(20px);
  //    transform: scale(1.25) translateX(20px);
  //    -webkit-transform-origin: right;
  //    transform-origin: right;
  //  }
  //  100% {
  //    -webkit-transform: scale(1) translate(0, 0);
  //    transform: scale(1) translate(0, 0);
  //    -webkit-transform-origin: 84% 50%;
  //    transform-origin: 84% 50%;
  //  }
  //}

  @keyframes bg-pan-right {
    0% {
      background-position: 0% 25%;
      transform: scale(1);
    }
    50% {
      background-position: 100% 65%;
      transform: scale(1.2);
    }
    100% {
      background-position: 0% 25%;
      transform: scale(1);
    }
  }
`;
