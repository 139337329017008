import React, { Fragment } from 'react';
import { AvailableLanguage, availableLanguages } from '../../../constants/availableLanguages';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LanguageHandlerStyles } from './LanguageHandlerStyles';
import { useWindowSize } from 'usehooks-ts';

function LanguageHandler() {
  const { i18n } = useTranslation();
  const { width } = useWindowSize();

  const changeLanguage = (lng: AvailableLanguage) => {
    i18n.changeLanguage(lng);
  };

  return (
    <LanguageHandlerStyles>
      {availableLanguages.map((language, index) => {
        if (index !== language.length - 1) {
          return (
            <Fragment key={language}>
              <button className={classNames(`${width >= 768 ? 'textSemiBold18' : 'textSemiBold22'} animationUnderline`, { active: i18n.language === language })} type="button" onClick={() => i18n.changeLanguage(language)}>{language.toUpperCase()}</button>
              <div className="splitter" />
            </Fragment>
          );
        }

        return (
          <button key={language} className={classNames(`${width >= 768 ? 'textSemiBold18' : 'textSemiBold22'} animationUnderline`, { active: i18n.language === language })} type="button" onClick={() => i18n.changeLanguage(language)}>{language.toUpperCase()}</button>
        );
      })}
    </LanguageHandlerStyles>
  );
}

export default LanguageHandler;
