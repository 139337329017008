import React, { useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import Logo from '../other/logo/Logo';
import { useTranslation } from 'react-i18next';
import TextButton from '../buttons/textButton/TextButton';
import classNames from 'classnames';
import Menu from '../menu/Menu';
import LanguageHandler from '../other/languageHandler/LanguageHandler';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

function Header() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const { t } = useTranslation('translations');
  const navigate = useNavigate();

  return (
    <>
      <Menu isOpen={menuOpen} />

      <HeaderStyles className="mainBg">
        <div className="container">
          <div className="leftSide">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={classNames('menuButton', { open: menuOpen })} onClick={() => setMenuOpen(!menuOpen)} type="button">
              <div className="line" />
            </button>

            <Logo />
          </div>

          <div className="rightSide">
            <div className="contentDesktop">
              <Link className="textSemiBold18 animationUnderline" to="pricing">{t('pricing')}</Link>

              <LanguageHandler />
            </div>

            <TextButton
              text={t('tryForFree')}
              type="button"
              theme="white"
              onClick={() => navigate('test')}
            />
          </div>
        </div>
      </HeaderStyles>
    </>
  );
}

export default Header;
