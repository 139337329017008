import styled from 'styled-components';

export const TextWithDotStyles = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 8px;
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #00C8CB;
    border-radius: 100%;
    transform: translateY(0.5em);
    filter: drop-shadow(0px 0px 3px rgba(14, 211, 185, 0.30)) drop-shadow(0px 0px 12px rgba(14, 211, 185, 0.40));
  }
`;
