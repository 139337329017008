import React from 'react';
import { TextButtonStyles } from './TextButtonStyles';
import classNames from 'classnames';

interface TextButtonProps {
  text: string,
  onClick: () => void;
  theme?: 'inherit' | 'white',
  type?: 'button' | 'submit',
  disabled?: boolean,
}

function TextButton({
  text, onClick, disabled, theme = 'inherit', type = 'button',
}: TextButtonProps) {
  return (
    <TextButtonStyles onClick={onClick} className={`button ${theme}`} type={type || 'button'} disabled={disabled}>
      {text}
    </TextButtonStyles>
  );
}

export default TextButton;
