import styled from 'styled-components';

import grid from '../../assets/images/gridBG.svg';

export const PricingStyles = styled.section`
  position: relative;
  
  overflow: hidden;
  
  padding-top: 32px;
  
  @media (min-width: 768px) {
    padding-top: 64px;
  }

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    top: -100px;
    height: 564px;
    width: 100%;
    left: 0;
    background: linear-gradient(83.21deg,#3900C3 0%,#4C25AB 100%);
    mask-image: radial-gradient(rgba(0, 0, 0, 1) 31%, transparent 80%);
    
    @media (min-width: 1024px) {
      bottom: 20%;
      width: 100%;
      height: 800px;
      
      left: -50%;
      background: linear-gradient(83.21deg,#3900C3 0%,#4C25AB 100%);
      mask-image: radial-gradient(rgba(0, 0, 0, 1) 20%, transparent 70%);
    }
  }
  
  .container {
    position: relative;
    z-index: 1;
  }

  .grid {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url(${grid});
    background-repeat: repeat;
  }
  
  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: 12px;

    opacity: 0;
    transition: 1s ease opacity;

    &.active {
      opacity: 1;
    }
    
    @media (min-width: 768px) {
      gap: 16px;
    }
    

    h1 {
      text-align: center;
    }
  }

  .blocksWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    
    place-items: center;
    
    @media (min-width: 756px) {
      display: flex;
      place-items: initial;
    }
    
    flex-wrap: wrap;
    justify-content: center;
    
    gap: 24px;

    @media (min-width: 768px) {
      gap: 30px;
    }
  }
  
  .listBlock {
    max-width: 350px;
    width: 100%;
    height: 100%;

    @media (min-width: 756px) {
      height: auto;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Stroke, #64676C);
    background: var(--Cards, #212C43);
    gap: 12px;

    opacity: 0;
    
    transition: 1s ease opacity;
    
    &.active {
      opacity: 1;
    }
    
    .listsWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      margin-bottom: auto;
    }

    .listWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .firstBlock {
    display: flex;

    max-width: 350px;
    margin: auto auto 24px auto;
    
    ul {
      display: grid;
      gap: 8px;
      
      @media (min-width: 1188px) {
        max-width: 600px;
        width: 100%;
        grid-template-columns: auto auto;
        
        li:nth-child(1), li:nth-child(2) {
          grid-column: 1/2;
        }

        li:nth-child(3), li:nth-child(4) {
          grid-column: 2/3;
        }
        
        li:nth-child(3) {
          grid-row: 1/2;
        }

        li:nth-child(4) {
          grid-row: 2/3;
        }
      }
    }

    @media (min-width: 756px) {
      max-width: 724px;

      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }

    @media (min-width: 768px) {
      margin: auto auto 30px auto;
    }

    @media (min-width: 1188px) {
      max-width: initial;
    }
  }

`;
