import styled from 'styled-components';

export const LinksPathStyles = styled.div`
  ul {
    list-style: none;
    
    display: flex;
    align-items: center;
    
    li {
      margin: 0 4px;
    }
    
    li:first-child {
      margin-left: 0;
    }
  }

  &.active {
    color: #FFF;
  }
  
  a {
    color: rgba(255, 255, 255, 0.70);
    transition: 0.3s ease color;
    
    &:hover {
      color: #fff;
    }
  }
  
  li:first-child {
    svg {
      path {
        transition: 0.3s linear stroke-opacity;
      }
    }
    
    &:hover {
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }
  
  .homeLink {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 4px;
    align-items: center;
  }
`;
