import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitsStyles } from './BenefitsStyles';
import TextWithDot from '../../other/textWithDot/TextWithDot';
import { InView } from 'react-intersection-observer';

function Benefits() {
  const { t, i18n } = useTranslation('translations');

  const listData = useMemo(() => t('benefit_list', { returnObjects: true }), [i18n.language]);

  return (
    <BenefitsStyles>
      <div className="container">
        <InView as="h2" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="blockTitleMb textBold48">
          {t('benefit_title')}
        </InView>

        <InView as="ul" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)}>
          {
            listData.map((text) => (
              <li key={text}><TextWithDot text={text} /></li>
            ))
          }
        </InView>
      </div>
    </BenefitsStyles>
  );
}

export default Benefits;
