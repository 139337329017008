import React from 'react';
import { TextWithDotStyles } from './TextWithDotStyles';

function TextWithDot({ text, chilren, extraTextSizeClass }: {text?: string, chilren?: any, extraTextSizeClass?: string}) {
  return (
    <TextWithDotStyles className="textWithDot">
      <div className="dot" />

      {chilren || <span className={extraTextSizeClass || 'textBase18'}>{text || ''}</span>}

    </TextWithDotStyles>
  );
}

export default TextWithDot;
