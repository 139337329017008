import styled from 'styled-components';

export const MainLayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  min-height: 100vh;
  min-height: 100svh;
  
  main {
    display: flex;
    flex-grow: 1;
  }
`;
