import styled from 'styled-components';

export const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  
  padding: 16px 0;

  border-bottom: 1px solid #374151;
  
  @media (min-width: 768px) {
    padding: 20px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  
  .leftSide, .rightSide {
    display: flex;
    align-items: center;
  }
  
  .rightSide {
    gap: 40px;
    
    .contentDesktop {
      display: none;
      
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        gap: 40px;
      }
    }
  }
  
  .leftSide {
    gap: 16px;

    .menuButton {
      position: relative;
      
      width: 24px;
      height: 24px;
      
      @media (min-width: 768px) {
        display: none;
      }
      
      .line {
        width: 100%;
        height: 2px;
        background: #FFF;
        
        transition: 0.3s opacity;
      }
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        
        width: 100%;
        height: 2px;
        background: #FFF;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 5px;

        width: 100%;
        height: 2px;
        background: #FFF;
      }

      &::before,
      &::after {
        transition: 0.3s ease transform;
      }
      
      &.open {
        .line {
          opacity: 0;
        }
        
        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 2px;
          background: #FFF;
        }

        &::before {
          transform: translateY(-50%) rotate(45deg);
        }

        &::after {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
`;
