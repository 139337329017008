import React, { useMemo, useState } from 'react';
import { BannerStyles } from './BannerStyles';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';

function Banner() {
  const [activeTitleTextItemIndex, setActiveTitleTextItemIndex] = useState<number>(0);

  const { t, i18n } = useTranslation('translations');

  const titleTexts = useMemo(() => t('banner_title_list', { returnObjects: true }), [i18n.language]);

  return (
    <BannerStyles>
      <div className="bannerWrapper">
        <div className="grid" />
        <div className="bg" />
        <InView as="h1" onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="textBold60">
          <span>{t('banner_title_1')}</span>
          <span className="greenBg">
            {showTextByLetter({
              text: titleTexts[activeTitleTextItemIndex],
              callback: () => setActiveTitleTextItemIndex(
                (prev) => (prev + 1 < titleTexts.length ? prev + 1 : 0),
              ),
              delay: 70,
              deleteTextByLetterBeforeCallback: true,
            })}
          </span>
          <span>{t('banner_title_3')}</span>
        </InView>
      </div>
    </BannerStyles>
  );
}

export default Banner;
