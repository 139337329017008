import React from 'react';
import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import TextButton from '../../buttons/textButton/TextButton';
import { SloganStyles } from './SloganStyles';

function Slogan() {
  const { t } = useTranslation();

  return (
    <SloganStyles>
      <div className="container">
        <InView as="h2" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="blockTitleMb textBold48">
          {t('slogan_title')}
        </InView>

        <TextButton
          text={t('slogan_button')}
          theme="white"
          onClick={() => null}
        />
      </div>
    </SloganStyles>
  );
}

export default Slogan;
