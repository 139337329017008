import React, { useCallback, useRef, useState } from 'react';
import { SwiperCenteredSlidesPerViewAutoStyles } from './SwiperCenteredSlidesPerViewAutoStyles';
import 'swiper/css';

import { Swiper } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';

function SwiperCenteredSlidesPerViewAuto({
  buttonBorderColor,
  initialSlide = 0,
  offsetLeft,
  children,
  buttonsIconsColor,
  loop = true,
  disabledPrev = true,
  disabledNext = true,
  linkButtonText,
  link,
  centeredSlides = true,
  freeMode = false,
  offsetRight = 0,
  spaceBeetwen = 20,
  slidePerView = 1,
}: {
  children: any,
  centeredSlides?: boolean,
  buttonsIconsColor?: string,
  loop?: boolean,
  disabledPrev?: boolean,
  disabledNext?: boolean,
  link?: string,
  linkButtonText?: string,
  freeMode?: boolean,
  offsetRight?: number,
  offsetLeft?: number,
  initialSlide?: number,
  buttonBorderColor?: string,
  spaceBeetwen?: number,
  slidePerView?: number,
}) {
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [slidesCount, setSlidesCount] = useState<number>(0);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    const currentIndex = sliderRef.current.swiper.activeIndex;
    const newIndex = currentIndex - slidePerView;
    // @ts-ignore
    sliderRef.current.swiper.slideTo(newIndex);
  }, [slidePerView]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    const currentIndex = sliderRef.current.swiper.activeIndex;
    const newIndex = currentIndex + slidePerView;

    // @ts-ignore
    sliderRef.current.swiper.slideTo(newIndex);
  }, [slidePerView]);

  return (
    <SwiperCenteredSlidesPerViewAutoStyles className="sliderWrapper">
      <div className="sliderContent">
        <Swiper
          allowTouchMove={false}
          slidesPerView="auto"
          initialSlide={initialSlide}
          centeredSlides={centeredSlides}
          spaceBetween={8}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          loop={loop}
          className="mySwiper"
          // @ts-ignore
          ref={sliderRef}
          breakpoints={{
            // when window width is >= 640px
            300: {
              spaceBetween: 8,
            },
            600: {
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Pagination, Navigation]}
          freeMode={freeMode}
          // @ts-ignore
          onActiveIndexChange={(slider) => {
            setActiveSlideIndex(slider.activeIndex);
          }}
          onSlidesLengthChange={(swiper: any) => setSlidesCount(swiper.slides.length)}
          slidesOffsetAfter={offsetRight}
          slidesOffsetBefore={offsetLeft}
        >
          {children}
        </Swiper>

        <div className="sliderNavigation">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button ref={navigationPrevRef} type="button" className="prevButton">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="44" viewBox="0 0 38 44" fill="none">
              <g filter="url(#filter0_dd_29_153)">
                <path d="M23.9461 21.4094L13.3805 13.157C13.3529 13.1353 13.3197 13.1218 13.2847 13.1181C13.2498 13.1143 13.2145 13.1205 13.1829 13.136C13.1514 13.1514 13.1248 13.1754 13.1062 13.2052C13.0877 13.2351 13.078 13.2696 13.0781 13.3047V15.1164C13.0781 15.2312 13.132 15.3414 13.2211 15.4117L21.6586 22L13.2211 28.5883C13.1297 28.6586 13.0781 28.7688 13.0781 28.8836V30.6953C13.0781 30.8523 13.2586 30.9391 13.3805 30.843L23.9461 22.5906C24.0359 22.5206 24.1085 22.431 24.1585 22.3286C24.2084 22.2263 24.2344 22.1139 24.2344 22C24.2344 21.8861 24.2084 21.7737 24.1585 21.6714C24.1085 21.569 24.0359 21.4794 23.9461 21.4094Z" fill="#0ED3B9" />
              </g>
              <defs>
                <filter id="filter0_dd_29_153" x="-7" y="-3" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_29_153" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.827451 0 0 0 0 0.72549 0 0 0 0.3 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_29_153" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_29_153" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="6" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.827451 0 0 0 0 0.72549 0 0 0 0.4 0" />
                  <feBlend mode="normal" in2="effect1_dropShadow_29_153" result="effect2_dropShadow_29_153" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_29_153" result="shape" />
                </filter>
              </defs>
            </svg>
          </button>

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button ref={navigationNextRef} type="button" className="nextButton">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="44" viewBox="0 0 38 44" fill="none">
              <g filter="url(#filter0_dd_29_153)">
                <path d="M23.9461 21.4094L13.3805 13.157C13.3529 13.1353 13.3197 13.1218 13.2847 13.1181C13.2498 13.1143 13.2145 13.1205 13.1829 13.136C13.1514 13.1514 13.1248 13.1754 13.1062 13.2052C13.0877 13.2351 13.078 13.2696 13.0781 13.3047V15.1164C13.0781 15.2312 13.132 15.3414 13.2211 15.4117L21.6586 22L13.2211 28.5883C13.1297 28.6586 13.0781 28.7688 13.0781 28.8836V30.6953C13.0781 30.8523 13.2586 30.9391 13.3805 30.843L23.9461 22.5906C24.0359 22.5206 24.1085 22.431 24.1585 22.3286C24.2084 22.2263 24.2344 22.1139 24.2344 22C24.2344 21.8861 24.2084 21.7737 24.1585 21.6714C24.1085 21.569 24.0359 21.4794 23.9461 21.4094Z" fill="#0ED3B9" />
              </g>
              <defs>
                <filter id="filter0_dd_29_153" x="-7" y="-3" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_29_153" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.827451 0 0 0 0 0.72549 0 0 0 0.3 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_29_153" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_29_153" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="6" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.827451 0 0 0 0 0.72549 0 0 0 0.4 0" />
                  <feBlend mode="normal" in2="effect1_dropShadow_29_153" result="effect2_dropShadow_29_153" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_29_153" result="shape" />
                </filter>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </SwiperCenteredSlidesPerViewAutoStyles>
  );
}

export default SwiperCenteredSlidesPerViewAuto;
