import React from 'react';
import { FooterStyles } from './FooterStyles';
import Logo from '../other/logo/Logo';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const facebookIcon = (
  <svg className="facebookIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.39232 13.7495H6.59366C6.14135 13.7495 6 13.5799 6 13.1558V9.73522C6 9.28292 6.16962 9.14157 6.59366 9.14157H9.39232V6.65387C9.39232 5.52309 9.5902 4.44886 10.1556 3.45943C10.7492 2.44174 11.5973 1.76327 12.6716 1.3675C13.3783 1.11308 14.085 1 14.8483 1H17.6187C18.0145 1 18.1841 1.16962 18.1841 1.56539V4.78809C18.1841 5.18386 18.0145 5.35348 17.6187 5.35348C16.8554 5.35348 16.0922 5.35348 15.3289 5.38175C14.5656 5.38175 14.1698 5.74925 14.1698 6.54079C14.1416 7.38887 14.1698 8.20868 14.1698 9.08503H17.4491C17.9014 9.08503 18.071 9.25465 18.071 9.70696V13.1275C18.071 13.5799 17.9297 13.7212 17.4491 13.7212H14.1698V22.937C14.1698 23.4176 14.0285 23.5872 13.5196 23.5872H9.98598C9.56194 23.5872 9.39232 23.4176 9.39232 22.9935V13.7495Z" fill="white" />
  </svg>
);

const linkedinIcon = (
  <svg className="linkedinIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_62_1844)">
      <path d="M0.296842 7.98455H5.45305V23.4687H0.296842V7.98455ZM22.3749 9.39893C21.2918 8.2166 19.8593 7.62532 18.0781 7.62532C17.4219 7.62532 16.8254 7.70604 16.2888 7.8676C15.7524 8.02905 15.2994 8.25556 14.9295 8.54726C14.5598 8.83896 14.2654 9.10975 14.0468 9.35964C13.839 9.59699 13.6357 9.87347 13.4374 10.1862V7.98444H8.29653L8.31229 8.73454C8.32286 9.23469 8.32806 10.7763 8.32806 13.3595C8.32806 15.9429 8.31766 19.3126 8.29675 23.4688H13.4374V14.8281C13.4374 14.2969 13.4943 13.8752 13.6092 13.5624C13.8281 13.0309 14.1585 12.5859 14.6014 12.2267C15.0443 11.867 15.5936 11.6873 16.2501 11.6873C17.1456 11.6873 17.8047 11.9972 18.2267 12.617C18.6485 13.2367 18.8594 14.0935 18.8594 15.1873V23.4683H24V14.5939C23.9996 12.3125 23.4584 10.5809 22.3749 9.39893ZM2.9062 0.531372C2.04172 0.531372 1.34126 0.784159 0.804659 1.28924C0.26811 1.79443 0 2.43222 0 3.20323C0 3.96345 0.260394 4.59905 0.781236 5.10933C1.30191 5.61967 1.98951 5.87497 2.84364 5.87497H2.87489C3.74999 5.87497 4.45581 5.61988 4.99219 5.10933C5.52858 4.59905 5.79154 3.96367 5.7812 3.20323C5.7708 2.43228 5.50499 1.79443 4.98431 1.28924C4.46369 0.78394 3.77073 0.531372 2.9062 0.531372Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_62_1844">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const xIcon = (
  <svg className="xIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M0.0585144 0.768433L9.32465 13.1581L0 23.2315H2.0986L10.2623 14.4121L16.8584 23.2315H24L14.2125 10.1449L22.8918 0.768433H20.7932L13.2748 8.8909L7.20014 0.768433H0.0585144ZM3.14467 2.31427H6.42555L20.9134 21.6854H17.6325L3.14467 2.31427Z" fill="white" />
  </svg>
);

function Footer() {
  const { t } = useTranslation('translations');

  return (
    <FooterStyles>
      <div className="grid" />
      <div className="container">
        <Logo />

        <nav>
          <Link className="textSemiBold18 animationUnderline" to="pricing">{t('footer_nav_1')}</Link>
          <Link className="textSemiBold18 animationUnderline" to="privacy-policy">{t('footer_nav_2')}</Link>
          <Link className="textSemiBold18 animationUnderline" to="terms">{t('footer_nav_3')}</Link>
        </nav>

        <div className="socialMedias">
          <a href="">{facebookIcon}</a>
          <a href="">{linkedinIcon}</a>
          <a href="">{xIcon}</a>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
