import React, { useEffect, useMemo } from 'react';
import './App.css';
import {
  Navigate, Route, Routes, useLocation, useNavigate,
} from 'react-router';
import MainLayout from './components/layouts/mainLayout/MainLayout';
import MainPage from './pages/main/MainPage';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from './constants/availableLanguages';
import Pricing from './pages/pricing/Pricing';
import PrivacyPolicy from './pages/gdpr/privacyPolicy/PrivacyPolicy';
import Terms from './pages/gdpr/terms/Terms';

let prevLanguage: string = '';

function App() {
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    prevLanguage = i18n.language;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap';
    link.media = 'print';
    document.head.appendChild(link);
    link.onload = function () {
      link.media = 'all';
    };
  }, []);

  useEffect(() => {
    if (prevLanguage === i18n.language) {
      window.scroll(0, 0);
    }

    prevLanguage = i18n.language;
  }, [location]);

  useEffect(() => {
    const splitLocation = location.pathname.split('/');

    splitLocation[1] = i18n.language;

    navigate(splitLocation.join('/'));
  }, [i18n.language]);

  console.log(i18n.language, i18n.languages);

  return (
    <Routes>
      {availableLanguages.map((language) => (
        <Route key={language} element={<MainLayout />} path={language}>
          <Route path="" element={<MainPage />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
        </Route>
      ))}

      <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>
  );
}

export default App;
