import React from 'react';
import { PricingStyles } from './PricingStyles';
import { useTranslation } from 'react-i18next';
import LinksPath from '../../components/other/linksPath/LinksPath';
import { InView } from 'react-intersection-observer';
import TextWithDot from '../../components/other/textWithDot/TextWithDot';
import TextButton from '../../components/buttons/textButton/TextButton';

interface BlockItem {
  title: string,
  'listsData': {
    'title'?: string,
    'data': string[]
  }[]
}

function Pricing() {
  const { t } = useTranslation(['pricing', 'other']);

  return (
    <PricingStyles className="blockPaddingBottom page">
      <div className="grid" />
      <div className="container">
        <InView triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="titleWrapper blockTitleMb">
          <h1 className="textBold48">
            {t('pricing:title')}
          </h1>

          <LinksPath
            pathArr={[
              {
                name: t('other:home'),
                path: '',
              },
            ]}
          />
        </InView>

        <InView triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="block firstBlock">
          <h3 className="textBold24">{t('pricing:try_for_free_block.title', { keySeparator: '.' })}</h3>

          <ul>
            {t('pricing:try_for_free_block', { returnObjects: true }).listData.map((text) => <li key={text}><TextWithDot extraTextSizeClass="textBase16" text={text} /></li>)}
          </ul>

          <TextButton
            text={t('pricing:blocks_button')}
            theme="white"
            onClick={() => {}}
          />
        </InView>

        <div className="blocksWrapper">
          {[t('pricing:nodes_block', { returnObjects: true }),
            t('pricing:application_storage_block', { returnObjects: true }),
            t('pricing:databases_block', { returnObjects: true })].map((blockData: BlockItem) => (
              <InView triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="block listBlock" key={blockData.title}>
                <h3 className="textBold24">{blockData.title}</h3>

                <div className="listsWrapper">
                  {blockData.listsData.map((listItem) => (
                    <div key={listItem.title} className="listWrapper">
                      {listItem.title && <span className="textSemiBold22">{listItem.title}</span>}

                      <ul>
                        {listItem.data.map((text) => <li key={text}><TextWithDot extraTextSizeClass="textBase16" text={text} /></li>)}
                      </ul>
                    </div>
                  ))}
                </div>

                <TextButton
                  text={t('pricing:blocks_button')}
                  theme="inherit"
                  onClick={() => {}}
                />
              </InView>
          ))}
        </div>
      </div>
    </PricingStyles>
  );
}

export default Pricing;
