import styled from 'styled-components';

export const LanguageHandlerStyles = styled.header`
  display: flex;
  align-items: center;
  gap: 9px;
  
  .splitter {
    height: 34px;
    border-radius: 4px;
    width: 2px;
    background-color: #FFF;
  }
  
  button {
    color: rgba(255, 255, 255, 0.40);
    
    transition: 0.3s ease color;
    
    &.active {
      color: #FFF;
    }
  }
`;
