import styled from 'styled-components';

export const ReviewsStyles = styled.section`
  h2 {
    display: flex;
    gap: 12px;
    
    margin: 0 auto;
    
    opacity: 0;
    transition: 1s ease opacity;
    
    .titleText {
      text-align: center;
    }
    
    &.active {
      opacity: 1;
    }
    
    @media (min-width: 768px) {
      max-width: 510px;
    }

    @media (min-width: 1024px) {
      max-width: 1016px;
    }
  }
  
  .list {
    display: none;

    justify-content: space-between;
    gap: 30px;
    
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      flex: 1 1 0;
      
      padding: 16px;

      border-radius: 4px;
      border: 1px solid #64676C;
      background: var(--Cards, #212C43);
      box-sizing: border-box;

      &.animatedItem {
        transform: scale(0);
        opacity: 0;
        
        transition: 0.5s transform, 0.5s opacity;
        
        &.active {
          transform: scale(1);
          opacity: 1;
        }
      }
      
      @media (min-width: 1024px) {
        padding: 24px;
        gap: 32px;
        
        font-size: 22px;
        font-weight: 600;
      }
      
      .description {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        
        @media (min-width: 1024px) {
          font-size: 22px;
          font-weight: 600;
        }
      }
      
      .itemUserInfo {
        display: flex;
        gap: 8px;
        align-items: center;
        
        img {
          width: 40px;
          height: 40px;
        }
        
        .itemUserNameAndPosition {
          display: flex;
          flex-direction: column;
          
          .name {
            font-size: 14px;
            line-height: 22px;
            
            @media (min-width: 1024px) {
              font-size: 18px;
              line-height: 26px;
            }
          }
          
          .position {
            font-size: 10px;
            line-height: 16px;
            
            @media (min-width: 1024px) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    
    @media (min-width: 768px) {
      display: flex;
    }
  }
  
  .contentMobile {
    @media (min-width: 768px) {
      display: none;
    }
    
    width: 100%;
    overflow: hidden;
    
    display: block;

    .slide {
      height: auto;
      width: fit-content;

      .item {
        width: 210px;
        height: 100%;
      }
    }
  }
`;
