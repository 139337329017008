import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewsStyles } from './ReviewsStyles';
import { InView } from 'react-intersection-observer';
import Counter from './counter/Counter';
import { SwiperSlide } from 'swiper/react';
import SwiperCenteredSlidesPerViewAuto
  from '../../slider/swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';

import userImage1 from '../../../assets/images/reviews/user1.png';
import userImage2 from '../../../assets/images/reviews/user2.png';
import userImage3 from '../../../assets/images/reviews/user3.png';

const images = [userImage1, userImage2, userImage3];

function Reviews() {
  const { t } = useTranslation();

  return (
    <ReviewsStyles className="blockPaddingBottom">
      <div className="container">
        <div className="titleWrapper blockTitleMb">
          <InView triggerOnce as="h2" onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className=" titleWrapper">
            <Counter />
            {' '}
            <span className="textBold48 titleText">
              {t('reviews_title')}
            </span>
          </InView>
        </div>

        <ul className="list">
          {t('reviews_list', { returnObjects: true }).map((item, index) => (
            <InView threshold={1} as="li" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="item animatedItem" key={item.name}>
              <p className="description">{item.description}</p>

              <div className="itemUserInfo">
                <img src={images[index]} alt={item.name} />

                <div className="itemUserNameAndPosition">
                  <span className="name textSemiBold">{item.name}</span>

                  <span className="position textSemiBold">{item.position}</span>
                </div>
              </div>
            </InView>
          ))}
        </ul>
      </div>

      <div className="list contentMobile">
        <SwiperCenteredSlidesPerViewAuto
          disabledNext
          disabledPrev
          centeredSlides={false}
          loop={false}
          offsetLeft={16}
          offsetRight={16}
        >
          {t('reviews_list', { returnObjects: true }).map((item, index) => (
            <SwiperSlide key={item.name} className="slide">
              <div className="item">
                <p className="description">{item.description}</p>

                <div className="itemUserInfo">
                  <img src={images[index]} alt={item.name} />

                  <div className="itemUserNameAndPosition">
                    <span className="name textSemiBold">{item.name}</span>

                    <span className="position textSemiBold">{item.position}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCenteredSlidesPerViewAuto>

      </div>
    </ReviewsStyles>
  );
}

export default Reviews;
