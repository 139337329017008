import React from 'react';
import { MenuStyles } from './MenuStyles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageHandler from '../other/languageHandler/LanguageHandler';

interface MenuProps {
  isOpen: boolean,
}

function Menu({ isOpen }: MenuProps) {
  const { t } = useTranslation('translations');

  return (
    <MenuStyles className={classNames('mainBg', { open: isOpen })}>
      <div />
      <Link className="renadeFont textBold24" to="pricing">{t('pricing')}</Link>
      <LanguageHandler />
    </MenuStyles>
  );
}

export default Menu;
