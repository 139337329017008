import React from 'react';
import { GDPRStyles } from '../GDPRStyles';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import LinksPath from '../../../components/other/linksPath/LinksPath';
import TextWithDot from '../../../components/other/textWithDot/TextWithDot';

function Terms() {
  const { t } = useTranslation('terms');

  return (
    <GDPRStyles className="page terms">
      <div className="container">
        <InView triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="titleWrapper blockTitleMb">
          <h1 className="textBold48">
            {t('title')}
          </h1>

          <LinksPath
            pathArr={[
              {
                name: t('title'),
                path: '',
              },
            ]}
          />
        </InView>

        <div className="content">
          <div className="block">
            <h3 className="textBold24">{t('terms_1')}</h3>
            <span className="textBase18">
              <Trans
                i18nKey="terms:terms_2"
                ns={['terms']}
                components={[
                  <a key={0} href={process.env.REACT_APP_RELMAN_URL} target="_blank" rel="noreferrer">
                    \\
                  </a>,
                ]}
              />
            </span>

            <span className="textBase18">{t('terms_3')}</span>
            <span className="textBase18">{t('terms_4')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_5')}</h3>
            <span className="textBase18">{t('terms_6')}</span>
            <span className="textBase18">{t('terms_7')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_8')}</h3>
            <span className="textBase18">{t('terms_9')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_10')}</h3>
            <ul>
              {t('terms_11', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
            <span className="textBase18">{t('terms_12')}</span>
            <span className="textBase18">{t('terms_13')}</span>
            <span className="textBase18">{t('terms_14')}</span>
            <ul>
              {t('terms_15', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_16')}</h3>
            <span className="textBase18">{t('terms_17')}</span>
            {t('terms_18', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            <span className="textBase18">{t('terms_19')}</span>
            <span className="textBase18">{t('terms_20')}</span>
            <ul>
              <li><TextWithDot text={t('terms_21_1')} /></li>
              <li>
                <TextWithDot chilren={(
                  <span className="textBase18">
                    <Trans
                      i18nKey="terms:terms_21_2"
                      ns={['terms']}
                      components={[
                        <a key={0} href="https://dot.com" target="_blank" rel="noreferrer">
                          \\
                        </a>,
                      ]}
                    />
                  </span>
                )}
                />
              </li>
              <li><TextWithDot text={t('terms_21_3')} /></li>
              <li><TextWithDot text={t('terms_21_4')} /></li>
              <li><TextWithDot text={t('terms_21_5')} /></li>
              <li><TextWithDot text={t('terms_21_6')} /></li>
            </ul>
            <span className="textBase18">{t('terms_22')}</span>
            <span className="textBase18">{t('terms_23')}</span>
            <span className="textBase18">{t('terms_24')}</span>
            <span className="textBase18">{t('terms_25')}</span>
            {t('terms_26', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_27')}</h3>
            <span className="textBase18">{t('terms_28')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_29')}</h3>
            <span className="textBase18">{t('terms_30')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_31')}</h3>
            <span className="textBase18">{t('terms_32')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_33')}</h3>
            <span className="textBase18">{t('terms_34')}</span>
            <span className="textBase18">{t('terms_35')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('terms_36')}</h3>
            <span className="textBase18">{t('terms_37')}</span>
            <ul>
              {t('terms_38', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
            <span className="textBase18">{t('terms_39')}</span>
            <span className="textBase18">{t('terms_40')}</span>
          </div>
        </div>
      </div>
    </GDPRStyles>
  );
}

export default Terms;
