import styled from 'styled-components';

export const HowItWorksStyles = styled.section`
  h2 {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    transition: 1s ease opacity;
    
    &.active {
      opacity: 1;
    }
  }
  
  .list {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    
    row-gap: 56px;
    
    @media(min-width: 768px) {
      row-gap: 66px;
    }

    @media(min-width: 1024px) {
      row-gap: 86px;
    }
    
    .itemWrapper {
      position: relative;
      height: fit-content;

      .arrow {
        position: absolute;
      }
      
      &.active {
        &:nth-child(1) {
          .item {
            transform: translateX(0);
            opacity: 1;
          }
        }
        
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          .item {
            transform: translateX(0);
            transition-delay: 0.6s;
            opacity: 1;
          }
          
          .arrow {
            path {
              animation: drawLine 0.6s ease-in-out forwards;
            }
          }
        }
      }
      
      svg {
        path {
          stroke-dasharray: 1000;
          stroke-dashoffset: -1000;
          stroke: #753EC6;
          stroke-linecap: round;
        }
      }
      
      &:nth-child(3) {
        svg {
          path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            stroke: #753EC6;
            stroke-linecap: round;
          }
        }
      }

      &:nth-child(1), &:nth-child(3) {
        .item {
          transform: translateX(-100%);
          opacity: 0;
        }
      }

      &:nth-child(4), &:nth-child(2) {
        .item {
          transform: translateX(100%);
          opacity: 0;
        }
      }

      &:nth-child(2) {
        .arrow {
          top: -100%;
          left: 20px;
          transform: rotate(30deg) translateY(-5px);

          svg {
            width: 80.797px;
            height: 78.832px;
          }
        }
      }

      &:nth-child(3) {
        .arrow {
          top: -100%;
          left: 50px;

          svg {
            width: 150.407px;
            height: 70.581px;
          }
        }
      }

      &:nth-child(4) {
        .arrow {
          transform: translateY(-100%) rotate(20deg);
          left: 27px;
          top: 0;

          svg {
            width: 70.407px;
            height: 60.581px;

            path {
              stroke-width: 7px;
            }
          }
        }
      }

      @media (min-width: 768px) {
        width: 75%;

        &:nth-child(2n) {
          margin-left: auto;
        }

        &:nth-child(2) {
          .arrow {
            top: -100%;
            left: -120px;
            transform: rotate(20deg);

            svg {
              width: 120.797px;
              height: 122.832px;
            }
          }
        }

        &:nth-child(3) {
          .arrow {
            top: -100%;
            left: 300px;

            svg {
              width: 163.407px;
              height: 83.581px;
            }
          }
        }

        &:nth-child(4) {
          .arrow {
            transform: translateX(-120%) translateY(-45px) rotate(20deg);

            svg {
              width: 149.118px;
              height: 130.553px;

              path {
                stroke-width: 4px;
              }
            }
          }
        }
      }

      @media (min-width: 1440px) {
        width: 50%;

        &:nth-child(2n) {
          margin-left: auto;
        }

        &:nth-child(2) {
          .arrow {
            top: -100%;
            left: -450px;

            transform: rotate(0) translateY(20px);

            svg {
              width: 400.5px;
            }
          }
        }

        &:nth-child(3) {
          .arrow {
            top: -100%;
            left: auto;
            right: 0;
            transform: translateX(100%);

            svg {
              width: 268.971px;
              height: 100.189px;
            }
          }
        }

        &:nth-child(4) {
          .arrow {
            transform: translateX(-120%) translateY(-45px) rotate(0);

            svg {
              width: 294.439px;
              height: 120.705px;

              path {
                stroke-width: 2px;
              }
            }
          }
        }
      }
    }
    
    .item {
      position: relative;
      z-index: 1;
      
      display: flex;
      align-items: center;
      gap: 16px;
      transition: 0.3s ease transform, 0.3s ease opacity;
      
      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        
        min-width: 60px;
        min-height: 60px;
        
        border-radius: 100%;
        color: #121212;
        background-color: #0ED3B9;
        filter: drop-shadow(0px 0px 3px rgba(14, 211, 185, 0.30)) drop-shadow(0px 0px 12px rgba(14, 211, 185, 0.40));
        
        @media (min-width: 768px) {
          min-width: 80px;
          min-height: 80px;
        }
      }
    }
  }

  @keyframes drawLine {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

//
// import styled from 'styled-components';
//
// export const HowItWorksStyles = styled.section`
//   h2 {
//     position: relative;
//     z-index: 1;
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     opacity: 0;
//     transition: 1s ease opacity;
//
//     &.active {
//       opacity: 1;
//     }
//   }
//
//   ul {
//     display: flex;
//     flex-direction: column;
//     grid-template-columns: 1fr 1fr;
//
//     row-gap: 56px;
//
//     @media(min-width: 768px) {
//       row-gap: 66px;
//     }
//
//     @media(min-width: 1024px) {
//       row-gap: 86px;
//     }
//
//     li {
//       position: relative;
//
//       display: flex;
//       align-items: center;
//       gap: 16px;
//
//       &:nth-child(1), &:nth-child(3) {
//         .number, .description {
//           transform: translateX(100%);
//         }
//       }
//
//       &:nth-child(4), &:nth-child(2) {
//
//       }
//
//       &:nth-child(2) {
//         .arrow {
//           top: -100%;
//           left: 20px;
//           transform: rotate(30deg) translateY(-5px);
//
//           svg {
//             width: 80.797px;
//             height: 78.832px;
//           }
//         }
//       }
//
//       &:nth-child(3) {
//         .arrow {
//           top: -100%;
//           left: 50px;
//
//           svg {
//             width: 150.407px;
//             height: 70.581px;
//           }
//         }
//       }
//
//       &:nth-child(4) {
//         .arrow {
//           transform: translateY(-100%) rotate(20deg);
//           left: 27px;
//
//           svg {
//             width: 70.407px;
//             height: 60.581px;
//
//             path {
//               stroke-width: 7px;
//             }
//           }
//         }
//       }
//
//       @media (min-width: 768px) {
//         width: 75%;
//
//         &:nth-child(2n) {
//           margin-left: auto;
//         }
//
//         &:nth-child(2) {
//           .arrow {
//             top: -100%;
//             left: -120px;
//             transform: rotate(20deg);
//
//             svg {
//               width: 120.797px;
//               height: 122.832px;
//             }
//           }
//         }
//
//         &:nth-child(3) {
//           .arrow {
//             top: -100%;
//             left: 300px;
//
//             svg {
//               width: 163.407px;
//               height: 83.581px;
//             }
//           }
//         }
//
//         &:nth-child(4) {
//           .arrow {
//             transform: translateX(-120%) translateY(-45px) rotate(20deg);
//
//             svg {
//               width: 149.118px;
//               height: 130.553px;
//
//               path {
//                 stroke-width: 4px;
//               }
//             }
//           }
//         }
//       }
//
//       @media (min-width: 1440px) {
//         width: 50%;
//
//         &:nth-child(2n) {
//           margin-left: auto;
//         }
//
//         &:nth-child(2) {
//           .arrow {
//             top: -100%;
//             left: -450px;
//
//             transform: rotate(0) translateY(20px);
//
//             svg {
//               width: 400.5px;
//             }
//           }
//         }
//
//         &:nth-child(3) {
//           .arrow {
//             top: -100%;
//             left: auto;
//             right: 0;
//             transform: translateX(100%);
//
//             svg {
//               width: 268.971px;
//               height: 100.189px;
//             }
//           }
//         }
//
//         &:nth-child(4) {
//           .arrow {
//             transform: translateX(-120%) translateY(-45px) rotate(0);
//
//             svg {
//               width: 294.439px;
//               height: 120.705px;
//
//               path {
//                 stroke-width: 2px;
//               }
//             }
//           }
//         }
//       }
//
//
//       .description, .number {
//         position: relative;
//         z-index: 1;
//
//         transition: 0.3s ease transform;
//       }
//
//       .number {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//
//         min-width: 60px;
//         min-height: 60px;
//
//         border-radius: 100%;
//         color: #121212;
//         background-color: #0ED3B9;
//         filter: drop-shadow(0px 0px 3px rgba(14, 211, 185, 0.30)) drop-shadow(0px 0px 12px rgba(14, 211, 185, 0.40));
//
//         @media (min-width: 768px) {
//           min-width: 80px;
//           min-height: 80px;
//         }
//       }
//
//       .arrow {
//         position: absolute;
//       }
//     }
//   }
// `;
