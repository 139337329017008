import React from 'react';
import Banner from '../../components/mainPage/banner/Banner';
import Benefits from '../../components/mainPage/benefits/Benefits';
import HowItWorks from '../../components/mainPage/howItWorks/HowItWorks';
import Reviews from '../../components/mainPage/reviews/Reviews';
import Slogan from '../../components/mainPage/slogan/Slogan';
import { MainPageStyles } from './MainPageStyles';

function MainPage() {
  return (
    <MainPageStyles className="page mainBG">
      <Banner />
      <Benefits />

      <HowItWorks />

      <div className="sloganAndReviewsWrapper blockPaddingBottom">
        <Reviews />
        <Slogan />
      </div>
    </MainPageStyles>
  );
}

export default MainPage;
