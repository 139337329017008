import React, { Fragment } from 'react';
import { LinksPathStyles } from './LinksPathStyles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6 12L10 8L6 4" stroke="white" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function LinksPath({ pathArr }: {pathArr: {path: string, name: string}[]}) {
  const { t } = useTranslation('other');

  return (
    <LinksPathStyles className="linksPath">
      <div className="container">
        <ul>
          <li>
            <Link to="/" className="homeLink textSemiBold16" title={t('go_to_page', { name: 'main' })}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2 5.99998L8 1.33331L14 5.99998V13.3333C14 13.6869 13.8595 14.0261 13.6095 14.2761C13.3594 14.5262 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5262 2.39052 14.2761C2.14048 14.0261 2 13.6869 2 13.3333V5.99998Z" stroke="white" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 14.6667V8H10V14.6667" stroke="white" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>{t('home')}</span>
            </Link>
          </li>
          {arrow}
          {pathArr.map((pathPart, index) => {
            if (pathArr.length !== index + 1) {
              return (
                <Fragment key={pathPart.path}>
                  <li className="textSemiBold16">
                    <Link to={pathPart.path} title={t('go_to_page', { name: pathPart.name })}>
                      <span>
                        {pathPart.name}
                      </span>
                    </Link>
                  </li>

                  {index + 1 < pathArr.length && arrow}
                </Fragment>
              );
            }

            return (
              <Fragment key={pathPart.path}>
                <li className="textSemiBold14 active">
                  <span>
                    {pathPart.name}
                  </span>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </div>
    </LinksPathStyles>
  );
}

export default LinksPath;
