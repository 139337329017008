import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 40px 0;
  
  border-top: 1px solid #374151;
  
  @media (min-width: 1024px) {
    padding: 68px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
  }
  
  .socialMedias {
    display: flex;
    gap: 24px;
    align-items: center;
    
    svg {
      path {
        transition: ease fill 0.3s;
      }
    }
    
    .facebookIcon:hover {
      path {
        fill: #1877f2;
      }
    }
    
    .linkedinIcon:hover {
      path {
        fill: #0077b5;
      }
    }
    
    .xIcon:hover {
      path {
        fill: #1DA1F2;
      }
    }
  }
`;
