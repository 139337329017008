const Arrow1 = (
  <svg xmlns="http://www.w3.org/2000/svg" width="406" height="151" viewBox="0 0 406 151" fill="none">
    <g filter="url(#filter0_dd_20_287)">
      <path d="M401.765 113.773C370.568 128.347 282.022 170.929 251.208 130.102C238.386 113.114 230.707 82.3473 237.977 60.852C253.588 14.6912 328.989 10.7875 304.826 70.5283C291.276 104.032 246.617 114.731 214.899 121.878C168.146 132.414 127 114.5 92.9566 92.5542C73.5114 80.6863 -3.0074 5.48145 4.02351 4.45238" stroke="#753EC6" strokeLinecap="round" />
    </g>
    <defs>
      <filter id="filter0_dd_20_287" x="0.0687256" y="0.952332" width="405.197" height="149.892" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.458824 0 0 0 0 0.243137 0 0 0 0 0.776471 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20_287" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.458824 0 0 0 0 0.243137 0 0 0 0 0.776471 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20_287" result="effect2_dropShadow_20_287" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_20_287" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Arrow1;
