import styled from 'styled-components';

import grid from '../../../assets/images/gridBG.svg';
import bg from '../../../assets/images/banner/bannerBg.svg';
import bgTablet from '../../../assets/images/banner/bannerBgTablet.svg';
import bgMobile from '../../../assets/images/banner/bannerBgMobile.svg';

export const BenefitsStyles = styled.section`
  padding: 80px 0;
  
  @media (min-width: 768px) {
    padding: 100px 0;
  }
  
  @media (min-width: 1024px) {
    padding: 140px 0;
  }
  
  h2 {
    text-align: center;

    opacity: 0;
    transition: 1s ease opacity;
    
    &.active {
      opacity: 1;
    }
  }
  
  ul {
    display: grid;
    grid-template-columns: 1fr;
    
    row-gap: 12px;

    opacity: 0;
    transition: 1s ease opacity;

    &.active {
      opacity: 1;
    }
    
    @media (min-width: 768px) {
      row-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    } 
    
    @media (min-width: 1024px) {
      row-gap: 24px;
      column-gap: 30px;
    }
  }
`;
