const Arrow2 = (
  <svg xmlns="http://www.w3.org/2000/svg" width="250" height="127" viewBox="0 0 250 127" fill="none">
    <g filter="url(#filter0_dd_20_286)">
      <path d="M245.756 3.67026C241.046 29.4601 221.265 68.8124 180.226 69.0828C157.502 69.2325 132.521 46.7459 152.942 23.9816C160.243 15.8426 164.366 22.6201 165.209 27.0263C166.741 35.0279 162.763 44.5189 155.922 53.0945C138.704 74.6798 112.715 70.9432 90.8143 76.127C62.3895 82.8549 25.9147 102.995 4.36359 123.508" stroke="#753EC6" strokeLinecap="round" />
    </g>
    <defs>
      <filter id="filter0_dd_20_286" x="0.863647" y="0.170166" width="248.393" height="126.837" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.458824 0 0 0 0 0.243137 0 0 0 0 0.776471 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20_286" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.458824 0 0 0 0 0.243137 0 0 0 0 0.776471 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20_286" result="effect2_dropShadow_20_286" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_20_286" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Arrow2;
