import styled from 'styled-components';

export const SwiperCenteredSlidesPerViewAutoStyles = styled.div`
  .mySwiper {
    cursor: pointer;
  }
  
  .sliderContent {
    position: relative;
    max-width: 100vw;
  }
  
  .prevButton, .nextButton {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 32px;
    height: 32px;
    border-radius: 100%;
    
    @media (min-width: 1330px) {
      position: absolute;

      width: 56px;
      height: 56px;
      
      svg {
        width: 38px;
        height: 38px;
      }
    }
    
    svg {
      transition: 0.3s ease opacity;

      width: 24px;
      height: 24px;
    }
    
    &:disabled {
      svg {
        opacity: 0.4;
      }
    }
  }
  
  .sliderNavigation {
    display: flex;
    justify-content: center;
    gap: 30px;

    padding-top: 32px;

    @media (min-width: 1330px) {
      display: block;
    }
  }
  
  .prevButton {
    svg {
      transform: rotate(180deg);
    }
    
    @media (min-width: 1330px) {
      top: 50%;
      left: -32px;
      transform: translate(-100%, -50%);
    }
  }

  .nextButton {
    @media (min-width: 1330px) {
      top: 50%;
      right: -32px;
      transform: translate(100%, -50%);
    }
  }
`;
