import React from 'react';
import { GDPRStyles } from '../GDPRStyles';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import LinksPath from '../../../components/other/linksPath/LinksPath';
import TextWithDot from '../../../components/other/textWithDot/TextWithDot';

function PrivacyPolicy() {
  const { t } = useTranslation('privacy_policy');

  return (
    <GDPRStyles className="page privacyPolicy">
      <div className="container">
        <InView triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="titleWrapper blockTitleMb">
          <h1 className="textBold48">
            {t('title')}
          </h1>

          <LinksPath
            pathArr={[
              {
                name: t('title'),
                path: '',
              },
            ]}
          />
        </InView>

        <div className="content">
          <div className="block">
            <span className="textBase18">{t('privacy_policy_1')}</span>
            <span className="textBase18">{t('privacy_policy_2')}</span>
            <span className="textBase18">{t('privacy_policy_3')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_4')}</h3>
            <span className="textBase18">{t('privacy_policy_5')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_6')}</h3>
            <span className="textBase18">{t('privacy_policy_7')}</span>
            <span className="textBase18">{t('privacy_policy_8')}</span>
            <span className="textBase18">{t('privacy_policy_9')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_10')}</h3>
            <span className="textBase18">{t('privacy_policy_11')}</span>
            <ul>
              {t('privacy_policy_12', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
            <span className="textBase18">{t('privacy_policy_13')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_14')}</h3>
            <span className="textBase18">{t('privacy_policy_15')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_16')}</h3>
            <span className="textBase18">{t('privacy_policy_17')}</span>
            <span className="textBase18">{t('privacy_policy_18')}</span>
            <span className="textBase18">{t('privacy_policy_19')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_20')}</h3>
            <span className="textBase18">{t('privacy_policy_21')}</span>
            <span className="textBase18">{t('privacy_policy_22')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_23')}</h3>
            <span className="textBase18">{t('privacy_policy_24')}</span>
            <ul>
              {t('privacy_policy_25', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_26')}</h3>
            <span className="textBase18">{t('privacy_policy_27')}</span>
            <ul>
              {t('privacy_policy_28', { returnObjects: true }).map((text) => (<li key={text}><TextWithDot text={text} /></li>))}
            </ul>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_29')}</h3>
            <span className="textBase18">{t('privacy_policy_30')}</span>
            <span className="textBase18">{t('privacy_policy_31')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_32')}</h3>
            <span className="textBase18">{t('privacy_policy_33')}</span>
            <span className="textBase18">{t('privacy_policy_34')}</span>
          </div>

          <div className="block">
            <h3 className="textBold24">{t('privacy_policy_35')}</h3>
            <span className="textBase18">{t('privacy_policy_36')}</span>
          </div>
        </div>
      </div>
    </GDPRStyles>
  );
}

export default PrivacyPolicy;
