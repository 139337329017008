import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HowItWorksStyles } from './HowItWorksStyles';
import { InView } from 'react-intersection-observer';

import Arrow1 from '../../../assets/icons/howItWorks/Arrow1';
import Arrow2 from '../../../assets/icons/howItWorks/Arrow2';
import Arrow3 from '../../../assets/icons/howItWorks/Arrow3';

function HowItWorks() {
  const { t, i18n } = useTranslation('translations');

  const listData = useMemo(() => t('how_it_works_list', { returnObjects: true }), [i18n.language]);

  return (
    <HowItWorksStyles className="blockPaddingBottom">
      <div className="container">
        <InView as="h2" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="blockTitleMb textBold48">
          {t('how_it_works_title')}
        </InView>

        <div className="list">
          {listData.map((text, index) => (
            <InView threshold={1} as="div" className="itemWrapper" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} key={text}>
              <div className="item">
                <div className="number textBold34">
                  {index + 1}
                </div>

                <span className="textBase18 description">{text}</span>
              </div>

              {index !== 0 ? (
                <InView as="div" className="arrow">
                  {index === 1 && Arrow1}
                  {index === 2 && Arrow2}
                  {index === 3 && Arrow3}
                </InView>
              ) : null}

            </InView>
          ))}
        </div>

        {/* <ul> */}
        {/*  {listData.map((text, index) => ( */}
        {/*    <InView as="li" triggerOnce onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} key={text}> */}
        {/*      <div className="number textBold34"> */}
        {/*        {index + 1} */}
        {/*      </div> */}

        {/*      <span className="textBase18 description">{text}</span> */}

        {/*      {index !== 0 ? ( */}
        {/*        <InView as="div" className="arrow"> */}
        {/*          {index === 1 && Arrow1} */}
        {/*          {index === 2 && Arrow2} */}
        {/*          {index === 3 && Arrow3} */}
        {/*        </InView> */}
        {/*      ) : null} */}

        {/*    </InView> */}
        {/*  ))} */}
        {/* </ul> */}
      </div>
    </HowItWorksStyles>
  );
}

export default HowItWorks;
