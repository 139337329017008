import styled from 'styled-components';

import bg from '../../assets/images/slogan/bg.svg';

export const MainPageStyles = styled.section`
  .sloganAndReviewsWrapper {
    position: relative;

    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: bottom;
  }
`;
