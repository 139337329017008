import styled from 'styled-components';

export const SloganStyles = styled.section`
  position: relative;

  .bg {
    position: absolute;
    left: 50%;
    
    transform: translateX(-50%);
    bottom: 0;
  }
  
  h2 {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: 1s ease opacity;
    max-width: 994px;
    
    &.active {
      opacity: 1;
    }
  }
  
  .button {
    width: 100%;
    
    @media (min-width: 768px) {
      width: fit-content;
      min-width: 180px;
    }
    
    @media (min-width: 1024px) {
      min-width: 190px;
    }
  }
`;
